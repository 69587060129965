import {Controller} from 'stimulus'
import throttle from 'lodash/throttle'
import Swiper, {Pagination, EffectFade} from 'swiper'

Swiper.use([Pagination, EffectFade])

export default class extends Controller {

  connect() {
    this.initSlider();
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy(true, true)
    }
  }

  /**
   * Initialize the slider
   * @returns {void}
   */
  initSlider() {
    this.swiper = new Swiper('.js-storiesSlider', {
      slidesPerView: 1,
      centeredSlides: false,
      speed: 800,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        700: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        1024: {
          slidesPerView: 3,
          centeredSlides: false,
        },
      },
    })
  }
}
